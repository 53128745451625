<template>
  <button
    @click="clickHandler"
    style="
      background-color: #009688;
      color: white;
      border-radius: 1.25rem;
      padding: 10px 20px;
      border: none;
      cursor: pointer;
      text-decoration: none;
    "
  >
    {{ buttonText }}
  </button>
</template>

<script src="./script.js"></script>
