import questionDetails from "../../../../data/question_details.js";

export default {
  name: "QuestionDialog",
  props: {
    selectedQuestionList: {
      type: Array,
      required: true,
    },
    modalTitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      questionTab: 0,
      selectedQuestion: {
        list: [],
        text: "",
      },
      questionDetail: "",
      imageSrc: "",
    };
  },
  methods: {
    showQuestion(item) {
      this.selectedQuestion.list = item;
      this.selectedQuestion.text = item[0];
      this.questionTab = 0; // 初期タブを0に設定
      this.dialog = true;
      console.log("selectedQuestion.text:", this.selectedQuestion.text);
      this.changeQuestionImg(this.selectedQuestion.text);
    },
    getQuestionDetails(question) {
      let filterd_question = question.substr(0, 3);
      this.questionDetail =
        questionDetails.myMap.get(filterd_question) ||
        "該当する設問がありません。";
    },
    changeQuestionImg(question) {
      let changedQuestionLowarCase = question.toLowerCase();
      console.log("changedQuestionLowarCase:", changedQuestionLowarCase);
      this.imageSrc = require(`@/assets/question_img/${changedQuestionLowarCase.substring(
        0,
        3
      )}.png`);
      this.getQuestionDetails(question);
    },
  },
};
