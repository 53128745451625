<template>
  <div>
    <v-chip label class="mr-1">{{ companyInfo.industry_name }}</v-chip>
    <v-chip
      v-for="(chip, index) in filteredChipConfigs"
      :key="index"
      label
      variant="outlined"
      :color="chip.color"
      class="mr-1"
    >
      {{ chip.label }}
    </v-chip>
  </div>
</template>

<script src="./script.js"></script>
