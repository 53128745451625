<template>
  <div class="card-container">
    <v-card v-if="mainCard" class="main-card">
      <v-card-subtitle class="main-card-subtitle">{{
        mainCard.subtitle1
      }}</v-card-subtitle>
      <v-card-title class="main-card-title">{{ mainCard.title }}</v-card-title>
      <v-card-subtitle class="main-card-subtitle">{{
        mainCard.subtitle2
      }}</v-card-subtitle>
      <v-card-text class="main-card-text">{{ mainCard.text1 }}</v-card-text>
      <v-card-subtitle class="main-card-subtitle">{{
        mainCard.subtitle3
      }}</v-card-subtitle>
      <v-card-text class="main-card-text">{{ mainCard.text2 }}</v-card-text>
      <v-card-subtitle class="main-card-subtitle">{{
        mainCard.subtitle4
      }}</v-card-subtitle>
      <v-card-text class="main-card-text">{{ mainCard.text3 }}</v-card-text>
    </v-card>
    <h3>{{ referenceTitle }}</h3>
    <v-card variant="text" style="margin: 0px !important">
      <v-card
        color="grey-lighten-4"
        v-for="item in referenceCards"
        :key="item.title"
      >
        <v-card-title  class="sub-card">
          {{ item.corporate_name }} 偏差値：{{ item.score }} 業種：{{
            item.industry
          }}
        </v-card-title>
        <v-card-subtitle>{{ item.subtitle1 }}</v-card-subtitle>
        <v-card-text>{{ item.text1 }}</v-card-text>
        <v-card-subtitle>{{ item.subtitle2 }}</v-card-subtitle>
        <v-card-text>{{ item.text2 }}</v-card-text>
        <v-card-subtitle>{{ item.subtitle3 }}</v-card-subtitle>
        <v-card-text>{{ item.text3 }}</v-card-text>
      </v-card>
    </v-card>
  </div>
</template>

<script src="./script.js"></script>
<style src="./style.css" lang="css" scoped></style>
