<template>
  <v-row>
    <v-col cols="12">
      <h2>⑦ 経営レベルの会議での議題化</h2>
    </v-col>
    <v-col cols="10">
      <h3>
        ■
        全社における健康経営の推進に関して、経営レベルの会議（取締役会や経営会議等）で議題にしている内容
      </h3>
      <BaseTable :headers="table7_headers" :data="desserts4" />
      <br /><br />
      <h3>■ 2022年度の会議⾃体の総実施回数と健康経営の推進を議題にした回数</h3>
      <BaseTable :headers="table7_2_headers" :data="desserts5" />
    </v-col> </v-row
  ><br /><br />
  <v-row>
    <v-col cols="12">
      <h2>⑧ 各種施策の2022年度の参加率</h2>
    </v-col>
    <v-col cols="10">
      <template v-for="item in desserts6" :key="item.name">
        <h3>■ {{ item.category }}</h3>
        <v-table>
          <tbody>
            <tr v-if="item.type === 2">
              <th class="text-left" width="20%">取組概要</th>
              <th class="text-left" width="30%" colspan="3">
                {{ item.summary }}
              </th>
            </tr>
            <tr v-if="item.type === 2">
              <th class="text-left" width="20%">取組内容</th>
              <th class="text-left" width="30%" colspan="3">{{ item.body }}</th>
            </tr>
            <tr v-if="item.type !== 3">
              <th class="text-left" width="20%">
                (a)対象者の比率<br />※全従業員に占める対象者の割合
              </th>
              <th class="text-left" width="30%">{{ item.aRate }} ％</th>
              <th class="text-left" width="20%">
                (b)参加者の比率<br />※対象者に占める参加者の割合
              </th>
              <th class="text-left" width="30%">{{ item.bRate }} ％</th>
            </tr>
          </tbody>
        </v-table>
        <v-table v-if="item.type === 3">
          <tbody>
            <tr>
              <th class="text-left" width="10%"></th>
              <th class="text-left" width="10%">実施</th>
              <th class="text-left" width="20%" colspan="2">参加率</th>
            </tr>
            <tr>
              <td class="text-left">女性限定</td>
              <td class="text-left">{{ item.womenFlgA }}</td>
              <td class="text-left">全女性従業員の</td>
              <td class="text-left">{{ item.aRate }} ％</td>
            </tr>
            <tr>
              <td class="text-left">管理職限定</td>
              <td class="text-left">{{ item.womenFlgB }}</td>
              <td class="text-left">全管理職の</td>
              <td class="text-left">{{ item.bRate }} ％</td>
            </tr>
            <tr>
              <td class="text-left">限定しない</td>
              <td class="text-left">{{ item.womenFlgC }}</td>
              <td class="text-left">全従業員の</td>
              <td class="text-left">{{ item.cRate }} ％</td>
            </tr>
          </tbody>
        </v-table>
        <br /><br />
      </template>
    </v-col> </v-row
  ><br /><br />
</template>
<script src="./script.js"></script>
<style src="./style.css" lang="css" scoped></style>
