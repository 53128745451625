import { CHIP_CONFIGS } from "@/config/const";

export default {
  name: "CompanyChips",
  props: {
    companyInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    filteredChipConfigs() {
      return CHIP_CONFIGS.filter((chip) => this.companyInfo[chip.flag]);
    },
  },
};
