import {
  fetchCompaniesByText,
  fetchCompaniesByIndustry,
  fetchCompaniesByInsurer,
  fetchAllCompanies,
} from "@/services/api/companyInfoService";
import CompanyChips from "@/components/molecules/company-chips/CompanyChips.vue";

export default {
  props: ["industryName", "industryCode", "insurerName"],
  components: {
    CompanyChips,
  },
  data: () => ({
    title: "",
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    checkbox: false,
    allCompanies: [],
    currentPage: 1,
    itemsPerPage: 9,
    searchText: "",
    searchPlaceholder: "企業名",
    totalPage: 0,
    isLoadingError: false,
  }),
  computed: {
    totalPages() {
      this.updateTotalPage();
      return this.totalPage;
    },
    paginatedCompanies() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.allCompanies.slice(start, end);
    },
  },
  methods: {
    async validate() {
      const { valid } = await this.$refs.form.validate();
      if (valid) alert("Form is valid");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    searchCompaniesByText() {
      this.currentPage = 1;
      if (this.searchText.length != 0) {
        fetchCompaniesByText(this.searchText)
          .then((response) => {
            this.allCompanies = response.data;
            this.title = "検索結果";
          })
          .catch((error) => {
            console.error("API呼び出しエラー", error);
          });
      } else {
        this.fetchData();
      }
    },
    searchCompaniesByIndustry(industryName, industryCode) {
      fetchCompaniesByIndustry(industryCode)
        .then((response) => {
          this.allCompanies = response.data;
          this.title = industryName;
          this.currentPage = 1;
        })
        .catch((error) => {
          console.error("API呼び出しエラー", error);
        });
    },
    searchCompaniesByInsurer(insurerName) {
      fetchCompaniesByInsurer(insurerName)
        .then((response) => {
          this.allCompanies = response.data;
          this.title = insurerName;
          this.currentPage = 1;
        })
        .catch((error) => {
          console.error("API呼び出しエラー", error);
        });
    },
    fetchData() {
      fetchAllCompanies()
        .then((response) => {
          this.allCompanies = response.data;
          this.title = "全企業一覧";
          this.currentPage = 1;
        })
        .catch((error) => {
          console.error("API呼び出しエラー", error);
          this.allCompanies = [];
          this.isLoadingError = true;
        });
    },
    handleCompanyClick(company) {
      console.log(company.code);
      if (company.code != 0) {
        this.$router.push({ name: "company", params: { code: company.code } });
      } else {
        console.log("この企業はクリックできません");
      }
    },
    updateTotalPage() {
      this.totalPage = Math.ceil(this.allCompanies.length / this.itemsPerPage);
    },
  },
  mounted() {
    if (this.insurerName !== null && this.insurerName !== undefined) {
      this.searchCompaniesByInsurer(this.insurerName);
    } else if (
      this.industryName !== "全業種" &&
      this.industryName !== null &&
      this.industryName !== undefined
    ) {
      this.searchCompaniesByIndustry(this.industryName, this.industryCode);
    } else {
      this.fetchData();
    }
  },
  watch: {
    industryName(newIndustryName) {
      if (newIndustryName == "全業種") {
        this.fetchData();
      } else {
        this.searchCompaniesByIndustry(newIndustryName, this.industryCode);
      }
    },
    insurerName(newInsurerName) {
      this.searchCompaniesByInsurer(newInsurerName);
    },
  },
};
