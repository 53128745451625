<template>
  <v-app>
    <v-app-bar color="white" dark app>
      <v-toolbar-title>
        <!-- <span @click="handleClick" style="cursor: pointer">WelCome</span> -->
        <div @click="handleClick">
          <v-img
            src="../assets/title.png"
            max-height="64"
            max-width="300"
            class="align-left"
            style="cursor: pointer"
          ></v-img>
        </div>
      </v-toolbar-title>
    </v-app-bar>
    <v-navigation-drawer
      width="300"
      permanent
      border="none"
      style="padding: 20px"
    >
      <v-list-item title="外部サイト"></v-list-item>
      <v-list-item
        v-for="nav in nav_lists"
        :key="nav.name"
        :href="nav.href"
        :prepend-avatar="nav.prependAvatarUrl"
        target="_blank"
      >
        <v-list-item-content class="d-flex align-center">
          <v-list-item-title style="font-size: 14px">{{
            nav.name
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list style="margin-top: 24px">
        <v-list-item><b>【保険組合】</b></v-list-item>
        <v-list-item>
          <v-combobox
            label="保険組合名"
            :items="filteredInsurerList"
            v-model="selectedInsurer"
            @input="filterInsurerList"
            @update:modelValue="handleInsurerSelect"
          ></v-combobox>
        </v-list-item>
      </v-list>
      <v-list style="margin-top: 24px">
        <v-list-item
          ><b>【業種】</b>
          <v-divider
            :thickness="3"
            class="border-opacity-75"
            style="margin-top: 12px"
            color="#009688"
          ></v-divider>
        </v-list-item>
        <v-list-item
          v-for="(industry, index) in companyCountList"
          :key="index"
          @click="handleListItemClick(industry.name, index + 1)"
        >
          <v-list-item-content
            class="d-flex justify-between"
            style="margin-top: 16px"
          >
            <span>{{ industry.name }}</span>
            <span v-if="industry.count !== null" style="margin-left: auto"
              >({{ industry.count }}社)</span
            >
          </v-list-item-content>
          <v-divider
            :thickness="3"
            class="border-opacity-75"
            color="#009688"
          ></v-divider>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script src="./script.js"></script>
<style src="./style.css" lang="css"></style>
