import { fetchCompanyDetails3 } from "@/services/api/companyInfoService";
import BaseTable from "@/components/molecules/base-table/BaseTable.vue";

// import CompanyInfo from "@/views/CompanyInfo.vue";
export default {
  components: {
    BaseTable,
  },
  props: ["companyInfo"],
  data: () => ({
    tab: null,

    desserts4: [
      {
        name: 1,
        apply: "",
        body: "健康経営の推進に関する体制",
      },
      {
        name: 2,
        apply: "〇",
        body: "従業員の健康課題の分析結果",
      },
      {
        name: 3,
        apply: "〇",
        body: "健康経営の具体的な取り組みと期待する効果のつながりの整理（戦略マップなど）",
      },
      {
        name: 4,
        apply: "〇",
        body: "従業員の健康状態や⽣活習慣に対する健康経営の取り組みの効果",
      },
      {
        name: 5,
        apply: "〇",
        body: "従業員の業務パフォーマンスや経営上の課題に対する健康経営の取り組みの効果",
      },
      {
        name: 6,
        apply: "〇",
        body: "健康経営の取り組みに関する外部からの評価（各種認定取得、IRや採⽤⾯での効果など）",
      },
      {
        name: 7,
        apply: "〇",
        body: "健康経営の取り組みに関する対外的な発信内容（投資家との対話、求⼈でのPRなど）",
      },
      {
        name: 8,
        apply: "",
        body: "その他",
      },
    ],
    desserts5: [
      {
        name: "(a)取締役会",
        num1: "",
        num2: "",
      },
      {
        name: "(b)経営レベルの会議（取締役会以外）",
        num1: "",
        num2: "",
      },
    ],
    desserts6: [
      {
        type: 1,
        category: "健康保持・増進に関する教育",
        summary: "",
        body: "",
        aRate: "",
        bRate: "",
        cRate: "",
        womenFlgA: "",
        womenFlgB: "",
        womenFlgC: "",
      },
      {
        type: 2,
        category: "コミュニケーション促進",
        summary: "",
        body: "",
        aRate: "",
        bRate: "",
        cRate: "",
        womenFlgA: "",
        womenFlgB: "",
        womenFlgC: "",
      },
      {
        type: 2,
        category: "食生活改善",
        summary:
          "⾷⽣活改善に向けたアプリ提供、カロリー記録等のサポートを実施している",
        body: "⽣活習慣改善アプリ「カロミル」を利⽤して、社員の⾷⽣活のサポートをしている",
        aRate: "",
        bRate: "",
        cRate: "",
        womenFlgA: "",
        womenFlgB: "",
        womenFlgC: "",
      },
      {
        type: 2,
        category: "運動習慣定着",
        summary:
          "運動奨励活動（歩数計の配布、歩⾏や階段使⽤の奨励、表彰等）や、運動促進のためのツールの提供（アプリ、動画配信等）を⾏っている",
        body: "個⼈、チームの期間中の平均歩数をカウントし、⽬標歩数を達成できるか競うウォーキングイベントを開催",
        aRate: "",
        bRate: "",
        cRate: "",
        womenFlgA: "",
        womenFlgB: "",
        womenFlgC: "",
      },
      {
        type: 3,
        category:
          "⼥性特有の健康関連課題に関する知識を得るための取り組み（セミナー等での教育）",
        summary: "",
        body: "",
        aRate: "",
        bRate: "",
        cRate: "",
        womenFlgA: "〇",
        womenFlgB: "〇",
        womenFlgC: "〇",
      },
    ],
    table7_headers: [
      { text: "番号", value: "name", width: "10%" },
      { text: "実施", value: "apply", width: "10%" },
      { text: "議題にしている内容", value: "body", width: "80%" },
    ],
    table7_2_headers: [
      { text: "", value: "name", width: "30%" },
      { text: "①総実施回数", value: "num1", width: "30%" },
      { text: "②健康経営を議題にした回数", value: "num2", width: "30%" },
    ],
  }),
  methods: {
    getHealthAgendaSymbol(value) {
      return value ? "〇" : "×";
    },
  },
  mounted() {
    fetchCompanyDetails3(this.companyInfo.code).then((response) => {
      let health_management_agenda_1 = response.data.health_management_agenda_1;
      this.desserts4[0].apply = this.getHealthAgendaSymbol(
        health_management_agenda_1
      );
      let health_management_agenda_2 = response.data.health_management_agenda_2;
      this.desserts4[1].apply = this.getHealthAgendaSymbol(
        health_management_agenda_2
      );
      let health_management_agenda_3 = response.data.health_management_agenda_3;
      this.desserts4[2].apply = this.getHealthAgendaSymbol(
        health_management_agenda_3
      );
      let health_management_agenda_4 = response.data.health_management_agenda_4;
      this.desserts4[3].apply = this.getHealthAgendaSymbol(
        health_management_agenda_4
      );
      let health_management_agenda_5 = response.data.health_management_agenda_5;
      this.desserts4[4].apply = this.getHealthAgendaSymbol(
        health_management_agenda_5
      );
      let health_management_agenda_6 = response.data.health_management_agenda_6;
      this.desserts4[5].apply = this.getHealthAgendaSymbol(
        health_management_agenda_6
      );
      let health_management_agenda_7 = response.data.health_management_agenda_7;
      this.desserts4[6].apply = this.getHealthAgendaSymbol(
        health_management_agenda_7
      );
      let health_management_agenda_8 = response.data.health_management_agenda_8;
      this.desserts4[7].apply = this.getHealthAgendaSymbol(
        health_management_agenda_8
      );
      this.desserts5[0].num1 = response.data.agenda_count_a_1;
      this.desserts5[0].num2 = response.data.agenda_count_a_2;
      this.desserts5[1].num1 = response.data.agenda_count_b_1;
      this.desserts5[1].num2 = response.data.agenda_count_b_2;
      this.desserts6[0].aRate = response.data.health_education_promotion_a;
      this.desserts6[0].bRate = response.data.health_education_promotion_b;
      this.desserts6[1].summary =
        response.data.communication_promotion_choice_content;
      this.desserts6[1].body =
        response.data.communication_promotion_approach_content;
      this.desserts6[1].aRate = response.data.communication_promotion_a;
      this.desserts6[1].bRate = response.data.communication_promotion_b;
      this.desserts6[2].summary = response.data.diet_improvement_choice_content;
      this.desserts6[2].body = response.data.diet_improvement_approach_content;
      this.desserts6[2].aRate = response.data.diet_improvement_a;
      this.desserts6[2].bRate = response.data.diet_improvement_b;
      this.desserts6[3].summary =
        response.data.exercise_habit_formation_choice_content;
      this.desserts6[3].body =
        response.data.exercise_habit_formation_approach_content;
      this.desserts6[3].aRate = response.data.exercise_habit_formation_a;
      this.desserts6[3].bRate = response.data.exercise_habit_formation_b;
      let womenFlgA = response.data.women_health_education_a_1;
      this.desserts6[4].womenFlgA = this.getHealthAgendaSymbol(womenFlgA);
      let womenFlgB = response.data.women_health_education_a_2;
      this.desserts6[4].womenFlgB = this.getHealthAgendaSymbol(womenFlgB);
      let womenFlgC = response.data.women_health_education_a_3;
      this.desserts6[4].womenFlgC = this.getHealthAgendaSymbol(womenFlgC);
      this.desserts6[4].aRate = response.data.women_health_education_b_1;
      this.desserts6[4].bRate = response.data.women_health_education_b_2;
      this.desserts6[4].cRate = response.data.women_health_education_b_3;
    });
  },
};
