<template>
  <v-row>
    <v-col>
      <h2>⑨ 具体的な健康課題への対応</h2>
      <h3>■ 健康経営度調査における健康課題の分類と対応度</h3>
      <v-table>
        <thead>
          <tr>
            <th class="text-left"></th>
            <th class="text-left"></th>
            <th class="text-left" colspan="2">課題への対応度</th>
            <th class="text-left"></th>
          </tr>
          <tr>
            <th class="text-left" width="10%">番号</th>
            <th class="text-left" width="30%">課題分類</th>
            <th class="text-left" width="10%">貴社</th>
            <th class="text-left" width="10%">業種平均</th>
            <th class="text-left" width="40%">対応する設問</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in health_issue_categories" :key="item.index">
            <td>
              <b>{{ item.index }}</b>
            </td>
            <td>{{ item.category }}</td>
            <td>{{ item.rank }}</td>
            <td>{{ item.average }}</td>
            <td>
              <QuestionDialog
                :selectedQuestionList="item.questions.split('、')"
                :modalTitle="'【' + item.category + '】に対応した調査票の設問'"
              />
            </td>
          </tr>
        </tbody>
      </v-table>
      <v-row>
        <v-col cols="6">
          <h3>■ 重点を置いている具体的な施策とその効果</h3>
          <CardList
            :mainCard="{
              subtitle1: '課題のテーマ',
              title: resolution_contents[0].title,
              subtitle2: '課題内容',
              text1: resolution_contents[0].resolution_content,
              subtitle3: '施策実施結果',
              text2: resolution_contents[0].implementation,
              subtitle4: '効果検証結果',
              text3: resolution_contents[0].effect_verification,
            }"
            referenceTitle="同じ課題のテーマを選んだ企業の回答例"
            :referenceCards="
              resolution_contents_0_reference.map((item) => ({
                corporate_name: item.corporate_name,
                score: item.score,
                industry: item.industry,
                subtitle1: '課題内容',
                text1: item.resolution_content,
                subtitle2: '施策実施結果',
                text2: item.implementation,
                subtitle3: '効果検証結果',
                text3: item.effect_verification,
              }))
            "
          />
        </v-col>
        <v-col cols="6">
          <br />
          <CardList
            :mainCard="{
              subtitle1: '課題のテーマ',
              title: resolution_contents[1].title,
              subtitle2: '課題内容',
              text1: resolution_contents[1].resolution_content,
              subtitle3: '施策実施結果',
              text2: resolution_contents[1].implementation,
              subtitle4: '効果検証結果',
              text3: resolution_contents[1].effect_verification,
            }"
            referenceTitle="同じ課題のテーマを選んだ企業の回答例"
            :referenceCards="
              resolution_contents_1_reference.map((item) => ({
                corporate_name: item.corporate_name,
                score: item.score,
                industry: item.industry,
                subtitle1: '課題内容',
                text1: item.resolution_content,
                subtitle2: '施策実施結果',
                text2: item.implementation,
                subtitle3: '効果検証結果',
                text3: item.effect_verification,
              }))
            "
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script src="./script.js"></script>
