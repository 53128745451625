<template>
  <div class="mb-10">
    <div class="title my-5">
      <v-row align="center" no-gutters style="height: 100px">
        <v-col cols="8">
          <v-sheet class="pa-2 ma-2">
            <h1>{{ title }}</h1>
          </v-sheet>
        </v-col>
        <v-col cols="4">
          <v-sheet class="pa-2 ma-2">
            <v-form ref="searchForm" @submit.prevent="searchCompaniesByText">
              <v-text-field
                v-model="searchText"
                :placeholder="searchPlaceholder"
                append-icon="mdi-magnify"
                @click:append="searchCompaniesByText"
                solo
              >
              </v-text-field>
            </v-form>
            <!-- <v-form ref="form">
							<v-select
								v-model="select"
								:items="items"
								:rules="[v => !!v || 'Item is required']"
								label="並び替え"
							></v-select>
						</v-form> -->
          </v-sheet>
        </v-col>
      </v-row>
      <v-divider
        :thickness="3"
        class="border-opacity-75"
        color="#009688"
      ></v-divider>
      <!-- ページコントロール -->
      <div class="ma-5 text-center">
        <button @click="prevPage" :disabled="currentPage <= 1">
          <v-chip color="teal cursor-pointer">前のページ</v-chip>
        </button>
        <span class="mx-3"
          >{{ currentPage }}ページ / {{ totalPage }}ページ</span
        >
        <button @click="nextPage" :disabled="currentPage >= totalPages">
          <v-chip color="teal cursor-pointer">次のページ</v-chip>
        </button>
      </div>

      <div class="tooltip-container">
        <div
          v-tooltip="{
            theme: 'custom-tooltip',
            content:
              '・現在公開中の情報（令和5年度）が閲覧可能です<br>・企業一覧では、【フィードバックシート非公開】の各種頭彰を取得している企業のデータも表示されます。',
          }"
          class="tooltip-area"
        >
          <p class="tooltip-text">WellComで扱うデータについて</p>
        </div>
      </div>
    </div>
    <div class="companyList">
      <v-row align="center" justify="start">
        <template v-if="!isLoadingError">
          <!-- 各画面サイズに応じたカラム幅の設定 -->
          <v-col
            v-for="company in paginatedCompanies"
            :key="company.code"
            cols="12"
            sm="12"
            md="6"
            xl="4"
          >
            <v-list-item
              style="margin: 10px"
              @click="handleCompanyClick(company)"
              :disabled="!company.submit_sheet_flag"
            >
              <v-card
                class="mx-auto"
                max-width="100%"
                :color="color"
                :company="company"
              >
                <v-card-item>
                  <div>
                    <div class="text-h6 mb-1">
                      <b>{{ company.corporate_name }}</b>
                    </div>
                    <p>
                      {{ company.insurer_name }}
                    </p>
                    <div class="my-3">
                      <CompanyChips :companyInfo="company" />
                    </div>
                    <p v-if="!company.submit_sheet_flag" class="disabled-sheet">
                      【フィードバックシート非公開】
                    </p>
                    <div v-if="company.submit_sheet_flag" class="text-caption">
                      総合順位：{{ company.overall_rank }}/ 3169社中
                    </div>
                    <div v-if="company.submit_sheet_flag" class="text-caption">
                      総合評価：{{ company.overall_deviation_value }}
                    </div>
                  </div>
                </v-card-item>
              </v-card>
            </v-list-item>
          </v-col>
        </template>
        <template v-else>
          <v-col cols="12">
            <v-card>
              <v-card-title
                >企業データの取得に失敗しました。時間を空けて再度お試しください。</v-card-title
              >
            </v-card>
          </v-col>
        </template>
      </v-row>
    </div>
  </div>
</template>
<script src="./script.js"></script>
<style src="./style.css" lang="css" scoped></style>
