<template>
  <div>
    <button class="question" @click="showQuestion(selectedQuestionList)">
      <p class="question-button-label">対応Q番号</p>
    </button>
    <v-dialog v-model="dialog" max-width="1000">
      <!-- ポップアップの内容 -->
      <v-card>
        <v-toolbar color="blue-grey">
          <v-toolbar-title>{{ modalTitle }}</v-toolbar-title>
        </v-toolbar>
        <div>
          <v-tabs v-model="questionTab">
            <v-tab
              v-for="(tabQuestion, index) in selectedQuestion.list"
              :key="index"
              @click="changeQuestionImg(tabQuestion)"
            >
              {{ tabQuestion }}
            </v-tab>
          </v-tabs>
        </div>
        <v-tabs-items v-model="questionTab">
          <v-tabs-items :value="selectedQuestion.text">
            <v-card-text>
              <b>{{ questionDetail }}</b>
            </v-card-text>
            <img :src="imageSrc" style="width: 100%" alt="Question Image" />
          </v-tabs-items>
        </v-tabs-items>
      </v-card>
    </v-dialog>
  </div>
</template>

<script src="./script.js"></script>
<style src="./style.css" lang="css" scoped></style>
