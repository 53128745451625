import axios from "axios";

export const fetchAllCompanies = () => {
  return axios.get("https://welcomeapi.armdxp.com/INIT");
};

export const fetchCompaniesByText = (searchText) => {
  const encodedSearchText = encodeURIComponent(searchText);
  return axios.get(
    `https://welcomeapi.armdxp.com/RESEARCH?researchWord=${encodedSearchText}`
  );
};

export const fetchCompaniesByIndustry = (industryCode) => {
  return axios.get(
    `https://welcomeapi.armdxp.com/RESEARCH?industry=${industryCode}`
  );
};

export const fetchCompaniesByInsurer = (insurerName) => {
  return axios.get(
    `https://welcomeapi.armdxp.com/RESEARCH?insurerName=${insurerName}`
  );
};

export const fetchInsurerList = () => {
  return axios.get("https://welcomeapi.armdxp.com/INSURER_LIST");
};

export const fetchCompanyCountByIndustry = () => {
  return axios.get(`https://welcomeapi.armdxp.com/COMPANY_COUNT_BY_INDUSTRY`);
};

export const fetchCompanyDetails = (code) => {
  return axios.get(`https://welcomeapi.armdxp.com/DETAIL_1?code=${code}`);
};

export const downloadPDF = (code) => {
  return axios.get(`https://welcomeapi.armdxp.com/EXTRACT_PDF?code=${code}`, {
    responseType: "blob",
  });
};

export const fetchCompanyDetails2 = (code) => {
  return axios.get(`https://welcomeapi.armdxp.com/DETAIL_2?code=${code}`);
};

export const fetchCompanyDetails3 = (code) => {
  return axios.get(`https://welcomeapi.armdxp.com/DETAIL_3?code=${code}`);
};

export const fetchCompanyDetails4 = (code) => {
  return axios.get(`https://welcomeapi.armdxp.com/DETAIL_4?code=${code}`);
};

export const fetchCompanyBasicInfo = (code) => {
  return axios.get(
    `https://welcomeapi.armdxp.com/BASIC_INFO?action_code=${String(code)}`
  );
}
export const fetchCpmpanyWorkStyleInfo = (code) => {
  return axios.get(`https://welcomeapi.armdxp.com/WORK_STYLE_INFO?action_code=${code}`);
};
