import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomePage/HomePage.vue";
import CompanyInfo from "../views/CompanyInfo/CompanyInfo.vue";

const routes = [
  {
    path: "/",
    name: "homepage",
    component: HomeView,
    meta: { title: "トップページ" },
    props: (route) => ({
      industryName: route.params.industryName,
      industryCode: route.params.industryCode,
      insurerName: route.params.insurerName,
    }),
  },
  {
    path: "/company/:code",
    name: "company",
    meta: { title: "企業ページ" },
    component: CompanyInfo,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
