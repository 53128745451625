import { fetchCompanyDetails4 } from "@/services/api/companyInfoService";
import CardList from "@/components/organisms/card-list/CardList.vue";
import QuestionDialog from "@/components/organisms/question-dialog/QuestionDialog.vue";
import { HEALTH_ISSUE_CATEGORIES } from "@/config/const";

export default {
  props: ["companyInfo"],
  components: {
    CardList,
    QuestionDialog,
  },
  data: () => ({
    tab: null,
    health_issue_categories: HEALTH_ISSUE_CATEGORIES,
    resolution_contents: [
      {
        title: "",
        resolution_content: "",
        implementation: "",
        effect_verification: "",
      },
      {
        title: "",
        resolution_content: "",
        implementation: "",
        effect_verification: "",
      },
    ],
    resolution_contents_0_reference: [],
    resolution_contents_1_reference: [],
    selectedQuestionList: [],
    modalTitle: "",
  }),
  methods: {
    initializeReferences() {
      for (let i = 0; i < 5; i++) {
        this.resolution_contents_0_reference.push({
          corporate_name: "",
          code: "",
          industry: "",
          score: "",
          resolution_content: "",
          implementation: "",
          effect_verification: "",
        });
        this.resolution_contents_1_reference.push({
          corporate_name: "",
          code: "",
          industry: "",
          score: "",
          resolution_content: "",
          implementation: "",
          effect_verification: "",
        });
      }
    },
  },
  mounted() {
    for (let i = 0; i < 2; i++) {
      this.resolution_contents.push({
        title: "",
        resolution_content: "",
        implementation: "",
        effect_verification: "",
      });
    }
    this.initializeReferences();
    fetchCompanyDetails4(this.companyInfo.code).then((response) => {
      for (let i = 1; i <= 10; i++) {
        this.health_issue_categories[i - 1].rank =
          response.data[`health_issue${i}`];
        this.health_issue_categories[i - 1].average =
          response.data[`industry_health_issue${i}`];
      }
      this.resolution_contents[0].title =
        response.data.health_issue1_resolution_theme_content;
      this.resolution_contents[0].resolution_content =
        response.data.health_issue1_resolution_content;
      this.resolution_contents[0].implementation =
        response.data.health_issue1_implementation;
      this.resolution_contents[0].effect_verification =
        response.data.health_issue1_effect_verification;
      this.resolution_contents[1].title =
        response.data.health_issue2_resolution_theme_content;
      this.resolution_contents[1].resolution_content =
        response.data.health_issue2_resolution_content;
      this.resolution_contents[1].implementation =
        response.data.health_issue2_implementation;
      this.resolution_contents[1].effect_verification =
        response.data.health_issue2_effect_verification;
      let health_issue1_lists = response.data.health_issue1_ls;
      let num_0 = 0;
      for (let health_issue1_ls of health_issue1_lists) {
        this.resolution_contents_0_reference[num_0].corporate_name =
          health_issue1_ls[0];
        this.resolution_contents_0_reference[num_0].code = health_issue1_ls[1];
        this.resolution_contents_0_reference[num_0].industry =
          health_issue1_ls[2];
        this.resolution_contents_0_reference[num_0].score = health_issue1_ls[3];
        this.resolution_contents_0_reference[num_0].resolution_content =
          health_issue1_ls[4];
        this.resolution_contents_0_reference[num_0].implementation =
          health_issue1_ls[5];
        this.resolution_contents_0_reference[num_0].effect_verification =
          health_issue1_ls[6];
        num_0 += 1;
      }
      let health_issue2_lists = response.data.health_issue2_ls;
      let num_1 = 0;
      for (let health_issue2_ls of health_issue2_lists) {
        this.resolution_contents_1_reference[num_1].corporate_name =
          health_issue2_ls[0];
        this.resolution_contents_1_reference[num_1].code = health_issue2_ls[1];
        this.resolution_contents_1_reference[num_1].industry =
          health_issue2_ls[2];
        this.resolution_contents_1_reference[num_1].score = health_issue2_ls[3];
        this.resolution_contents_1_reference[num_1].resolution_content =
          health_issue2_ls[4];
        this.resolution_contents_1_reference[num_1].implementation =
          health_issue2_ls[5];
        this.resolution_contents_1_reference[num_1].effect_verification =
          health_issue2_ls[6];
        num_1 += 1;
      }
    });
  },
};
