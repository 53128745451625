import { fetchCompanyDetails2 } from "@/services/api/companyInfoService";
import CardList from "@/components/organisms/card-list/CardList.vue";

export default {
  components: {
    CardList,
  },
  props: ["companyInfo"],
  data: () => ({
    tab: null,
    desserts5: [
      {
        choices_content: "",
        issue_content: "",
        content_effect: "",
      },
      {
        choices_content: "",
        issue_content: "",
        content_effect: "",
      },
    ],
    desserts5_1_reference: [
      {
        corporate_name: "",
        code: "",
        industry: "",
        score: "",
        issue_content: "",
        content_effect: "",
      },
      {
        corporate_name: "",
        code: "",
        industry: "",
        score: "",
        issue_content: "",
        content_effect: "",
      },
      {
        corporate_name: "",
        code: "",
        industry: "",
        score: "",
        issue_content: "",
        content_effect: "",
      },
      {
        corporate_name: "",
        code: "",
        industry: "",
        score: "",
        issue_content: "",
        content_effect: "",
      },
      {
        corporate_name: "",
        code: "",
        industry: "",
        score: "",
        issue_content: "",
        content_effect: "",
      },
    ],
    desserts5_2_reference: [
      {
        corporate_name: "",
        code: "",
        industry: "",
        score: "",
        issue_content: "",
        content_effect: "",
      },
      {
        corporate_name: "",
        code: "",
        industry: "",
        score: "",
        issue_content: "",
        content_effect: "",
      },
      {
        corporate_name: "",
        code: "",
        industry: "",
        score: "",
        issue_content: "",
        content_effect: "",
      },
      {
        corporate_name: "",
        code: "",
        industry: "",
        score: "",
        issue_content: "",
        content_effect: "",
      },
      {
        corporate_name: "",
        code: "",
        industry: "",
        score: "",
        issue_content: "",
        content_effect: "",
      },
    ],
    desserts6: [
      {
        health_management_media_choices_content: "",
        health_management_URL: "",
        employee_performance_info_a_1: "",
        employee_performance_info_a_2: "",
        employee_performance_info_a_3: "",
        employee_performance_info_a_4: "",
        employee_performance_info_b_1: "",
        employee_performance_info_b_2: "",
        employee_performance_info_b_3: "",
        employee_performance_info_b_4: "",
        employee_performance_info_c_1: "",
        employee_performance_info_c_2: "",
        employee_performance_info_c_3: "",
        employee_performance_info_c_4: "",
        employee_performance_info_a_URL: "",
        employee_performance_info_b_URL: "",
        employee_performance_info_c_URL: "",
      },
    ],
  }),
  methods: {},
  mounted() {
    fetchCompanyDetails2(this.companyInfo.code).then((response) => {
      this.desserts5[0].choices_content =
        response.data.health_management_issue_choices_content_1;
      this.desserts5[0].issue_content =
        response.data.health_management_issue_content_1;
      this.desserts5[0].content_effect =
        response.data.health_management_issue_content_effect_1;
      this.desserts5[1].choices_content =
        response.data.health_management_issue_choices_content_2;
      this.desserts5[1].issue_content =
        response.data.health_management_issue_content_2;
      this.desserts5[1].content_effect =
        response.data.health_management_issue_content_effect_2;
      let health_management_issue_answer_lists_1 =
        response.data.health_management_issue_answer_ls_1;
      let num_1 = 0;
      for (let answer_ls_0 of health_management_issue_answer_lists_1) {
        this.desserts5_1_reference[num_1].corporate_name = answer_ls_0[0];
        this.desserts5_1_reference[num_1].code = answer_ls_0[1];
        this.desserts5_1_reference[num_1].industry = answer_ls_0[2];
        this.desserts5_1_reference[num_1].score = answer_ls_0[3];
        this.desserts5_1_reference[num_1].issue_content = answer_ls_0[4];
        this.desserts5_1_reference[num_1].content_effect = answer_ls_0[5];
        num_1 += 1;
      }
      let health_management_issue_answer_lists_2 =
        response.data.health_management_issue_answer_ls_2;
      let num_2 = 0;
      for (let answer_ls_1 of health_management_issue_answer_lists_2) {
        this.desserts5_2_reference[num_2].corporate_name = answer_ls_1[0];
        this.desserts5_2_reference[num_2].code = answer_ls_1[1];
        this.desserts5_2_reference[num_2].industry = answer_ls_1[2];
        this.desserts5_2_reference[num_2].score = answer_ls_1[3];
        this.desserts5_2_reference[num_2].issue_content = answer_ls_1[4];
        this.desserts5_2_reference[num_2].content_effect = answer_ls_1[5];
        num_2 += 1;
      }
      this.desserts6[0].health_management_media_choices_content =
        response.data.health_management_media_choices_content;
      this.desserts6[0].health_management_URL =
        response.data.health_management_URL;
      this.desserts6[0].employee_performance_info_a_1 = response.data
        .employee_performance_info_a_1
        ? "〇"
        : "×";
      this.desserts6[0].employee_performance_info_a_2 = response.data
        .employee_performance_info_a_2
        ? "〇"
        : "×";
      this.desserts6[0].employee_performance_info_a_3 = response.data
        .employee_performance_info_a_3
        ? "〇"
        : "×";
      this.desserts6[0].employee_performance_info_a_4 = response.data
        .employee_performance_info_a_4
        ? "〇"
        : "×";
      this.desserts6[0].employee_performance_info_b_1 = response.data
        .employee_performance_info_b_1
        ? "〇"
        : "×";
      this.desserts6[0].employee_performance_info_b_2 = response.data
        .employee_performance_info_b_2
        ? "〇"
        : "×";
      this.desserts6[0].employee_performance_info_b_3 = response.data
        .employee_performance_info_b_3
        ? "〇"
        : "×";
      this.desserts6[0].employee_performance_info_b_4 = response.data
        .employee_performance_info_b_4
        ? "〇"
        : "×";
      this.desserts6[0].employee_performance_info_c_1 = response.data
        .employee_performance_info_c_1
        ? "〇"
        : "×";
      this.desserts6[0].employee_performance_info_c_2 = response.data
        .employee_performance_info_c_2
        ? "〇"
        : "×";
      this.desserts6[0].employee_performance_info_c_3 = response.data
        .employee_performance_info_c_3
        ? "〇"
        : "×";
      this.desserts6[0].employee_performance_info_c_4 = response.data
        .employee_performance_info_c_4
        ? "〇"
        : "×";
      this.desserts6[0].employee_performance_info_a_URL =
        response.data.employee_performance_info_a_URL;
      this.desserts6[0].employee_performance_info_b_URL =
        response.data.employee_performance_info_b_URL;
      this.desserts6[0].employee_performance_info_c_URL =
        response.data.employee_performance_info_c_URL;
    });
  },
};
