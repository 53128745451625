import BaseTable from "@/components/molecules/base-table/BaseTable.vue";
import QuestionDialog from "@/components/organisms/question-dialog/QuestionDialog.vue";
import { ASSESSMENT_ITEM_LIST } from "@/config/const";

export default {
  props: ["companyInfo"],
  components: {
    BaseTable,
    QuestionDialog,
  },
  data: () => ({
    tab: null,
    num_of_company: 3169,
    assessment_item_list: ASSESSMENT_ITEM_LIST,
    companyRankHistory: [
      {
        name: "総合順位",
        thisYear: null,
        oneYearsAgo: null,
        twoYearsAgo: null,
        threeYearsAgo: null,
        fourYearsAgo: null,
      },
      {
        name: "総合評価",
        thisYear: 0,
        oneYearsAgo: 0,
        twoYearsAgo: 0,
        threeYearsAgo: 0,
        fourYearsAgo: 0,
      },
      {
        name: "【参考】回答企業数",
        thisYear: "3520社",
        oneYearsAgo: "3169社",
        twoYearsAgo: "2869社",
        threeYearsAgo: "2523社",
        fourYearsAgo: "2328社",
      },
    ],
    headers: [
      { text: "", width: "25%" },
      { text: "令和5年度", value: "thisYear", width: "15%" },
      { text: "令和4年度", value: "oneYearsAgo", width: "15%" },
      { text: "令和3年度", value: "twoYearsAgo", width: "15%" },
      { text: "令和2年度", value: "threeYearsAgo", width: "15%" },
      { text: "令和1年度", value: "fourYearsAgo", width: "15%" },
    ],
    selectedQuestionList: [],
    modalTitle: "",
  }),
  created() {
    // companyInfoが渡された後にdessertsのデータを更新
    this.updateAssessmentItems();
  },
  methods: {
    updateAssessmentItems() {
      const companyData = this.companyInfo;

      this.assessment_item_list.forEach((item, i) => {
        if (i === 0) {
          item.companyRank = this.companyInfo.overall_deviation_value;
          item.avarageRankIndustory = this.companyInfo.industry_deviation_value;
        } else if (i >= 1 && i <= 4) {
          item.companyRank = companyData[`aspect${i}`];
          item.avarageRankIndustory = companyData[`industry_aspect${i}`];
        }

        item.details.forEach((detail, j) => {
          detail.companyRank = companyData[`detail${i}_${j + 1}`];
          detail.avarageRankIndustory =
            companyData[`industry_detail${i}_${j + 1}`];
        });
      });

      this.companyRankHistory[0].thisYear = companyData.overall_rank;
      this.companyRankHistory[0].oneYearsAgo =
        companyData.last_year_overall_rank;
      this.companyRankHistory[0].twoYearsAgo =
        companyData.two_years_ago_overall_rank;
      this.companyRankHistory[0].threeYearsAgo =
        companyData.three_years_ago_overall_rank;
      this.companyRankHistory[0].fourYearsAgo =
        companyData.four_years_ago_overall_rank;

      this.companyRankHistory[1].thisYear = companyData.overall_deviation_value;
      this.companyRankHistory[1].oneYearsAgo =
        companyData.last_year_overall_deviation_value;
      this.companyRankHistory[1].twoYearsAgo =
        companyData.two_years_ago_overall_deviation_value;
      this.companyRankHistory[1].threeYearsAgo =
        companyData.three_years_ago_overall_deviation_value;
      this.companyRankHistory[1].fourYearsAgo =
        companyData.four_years_ago_overall_deviation_value;
    },
    toggleRow(name, index) {
      const selectedItem = this.assessment_item_list.find(
        (item) => item.name === name
      );

      if (
        selectedItem &&
        Array.isArray(selectedItem.details) &&
        selectedItem.details.length
      ) {
        const nextRowIndex = index + 1;
        if (this.assessment_item_list[nextRowIndex]?.name.startsWith(" - ")) {
          this.assessment_item_list.splice(
            nextRowIndex,
            selectedItem.details.length
          );
        } else {
          selectedItem.details.forEach((detail, idx) => {
            this.assessment_item_list.splice(nextRowIndex + idx, 0, {
              ...detail,
              name: ` - ${detail.weight}`,
              weight: "",
            });
          });
        }
      }
    },
  },
  watch: {
    companyInfo: {
      handler: "updateAssessmentItems", // companyInfoが変更されたらupdateDessertsメソッドを呼び出す
      immediate: true, // 初期化時にも呼び出す
    },
  },
};
