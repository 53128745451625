import {
  fetchCompanyDetails,
  fetchCompanyBasicInfo,
  downloadPDF,
} from "@/services/api/companyInfoService";
import CompanyInfoTab1 from "@/components/tabs/CompanyInfoTab1/CompanyInfoTab1.vue";
import CompanyInfoTab2 from "@/components/tabs/CompanyInfoTab2/CompanyInfoTab2.vue";
import CompanyInfoTab3 from "@/components/tabs/CompanyInfoTab3/CompanyInfoTab3.vue";
import CompanyInfoTab4 from "@/components/tabs/CompanyInfoTab4/CompanyInfoTab4.vue";
import CompanyInfoTab5 from "@/components/tabs/CompanyInfoTab5/CompanyInfoTab5.vue";
import CompanyChips from "@/components/molecules/company-chips/CompanyChips.vue";
import DownloadButton from "@/components/atoms/buttons/download-button/DownloadButton.vue";
import BaseTable from "@/components/molecules/base-table/BaseTable.vue";

export default {
  components: {
    CompanyInfoTab1,
    CompanyInfoTab2,
    CompanyInfoTab3,
    CompanyInfoTab4,
    CompanyInfoTab5,
    CompanyChips,
    DownloadButton,
    BaseTable,
  },
  data: () => ({
    tab: null,
    companyInfo: {
      code: 0, // コード
      corporate_name: null, // 法人名
      english_corporate_name: null, // 英文法人名
      listed_status: false, // 上場フラグ
      brand_flag: false, // 健康経営銘柄フラグ
      white500_flag: false, // ホワイト500フラグ
      big_good_flag: false, // 優良法人（大）フラグ
      bright500_flag: false, // ブライト500フラグ
      small_good_flag: false, // 優良法人（中小）フラグ
      industry_code: 0, // 業種番号
      industry_name: null, // 業種名
      overall_lank: null, // 総合順位
      overall_evaluation: 0, // 総合評価
      overall_deviation_value: 0, // 総合偏差値
      aspect1: 0, // 経営理念・方針偏差値
      aspect2: 0, // 組織体制偏差値
      aspect3: 0, // 評価・施策実行偏差値
      aspect4: 0, // 評価改善偏差値
      industry_deviation_value: 0, // 総合偏差値の業種平均
      industry_aspect1: 0, // 経営理念・方針偏差値の業種平均
      industry_aspect2: 0, // 組織体制偏差値の業種平均
      industry_aspect3: 0, // 評価・施策実行偏差値の業種平均
      industry_aspect4: 0, // 評価改善偏差値の業種平均
      last_year_overall_rank: null, // 1年前の結果
      last_year_oerall_deviation_value: 0,
      last_year_aspect1: 0,
      last_year_aspect2: 0,
      last_year_aspect3: 0,
      last_year_aspect4: 0,
      two_years_ago_overall_rank: null, // 2年前の結果
      two_years_ago_oerall_deviation_value: 0,
      two_years_ago_aspect1: 0,
      two_years_ago_aspect2: 0,
      two_years_ago_aspect3: 0,
      two_years_ago_aspect4: 0,
      three_years_ago_overall_rank: null, // 3年前の結果
      three_years_ago_oerall_deviation_value: 0,
      three_years_ago_aspect1: 0,
      three_years_ago_aspect2: 0,
      three_years_ago_aspect3: 0,
      three_years_ago_aspect4: 0,
      four_years_ago_overall_rank: null, // 4年前の結果
      four_years_ago_oerall_deviation_value: 0,
      four_years_ago_aspect1: 0,
      four_years_ago_aspect2: 0,
      four_years_ago_aspect3: 0,
      four_years_ago_aspect4: 0,
      detail1_1: 0, // 評価項目詳細偏差値
      detail1_2: 0,
      detail2_1: 0,
      detail2_2: 0,
      detail2_3: 0,
      detail3_1: 0,
      detail3_2: 0,
      detail3_3: 0,
      detail3_4: 0,
      detail3_5: 0,
      detail4_1: 0,
      detail4_2: 0,
      detail4_3: 0,
      industry_detail1_1: 0, // 評価項目詳細偏差値の業種平均
      industry_detail1_2: 0,
      industry_detail2_1: 0,
      industry_detail2_2: 0,
      industry_detail2_3: 0,
      industry_detail3_1: 0,
      industry_detail3_2: 0,
      industry_detail3_3: 0,
      industry_detail3_4: 0,
      industry_detail3_5: 0,
      industry_detail4_1: 0,
      industry_detail4_2: 0,
      industry_detail4_3: 0,
      insurer_name: null, // 保険協会名
    },
    companyBasicInfo: {},
    headers: [
      { text: "基本情報", width: "20%" },
      { text: "", value: "value", width: "80%" },
    ],
  }),
  methods: {
    async downloadPDF() {
      try {
        const response = await downloadPDF(this.$route.params.code);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "extracted_pdf.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("PDFのダウンロードに失敗しました:", error);
      }
    },
  },
  mounted() {
    fetchCompanyDetails(this.$route.params.code)
      .then((response) => {
        this.companyInfo = response.data;
      })
      .catch((error) => {
        console.error("企業情報の取得に失敗しました:", error);
      });
    fetchCompanyBasicInfo(this.$route.params.code)
      .then((response) => {
        const data = response.data;

        // valueがnull、法人番号の辞書を削除し、エントリの配列に変換
        this.companyBasicInfo = Object.entries(data)
          .filter(
            ([key, value]) =>
              value !== null && key !== "法人番号" && key !== "企業規模詳細"
          )
          .map(([key, value]) => {
            if (key === "企業ホームページ") {
              return {
                name: key,
                value: `<a href="${value}" target="_blank">${value}</a>`,
              };
            }
            if (key === "企業規模" && data["企業規模詳細"]) {
              return { name: key, value: `${value} ${data["企業規模詳細"]}` };
            }
            return { name: key, value: value };
          });
      })
      .catch((error) => {
        console.error("企業基本情報の取得に失敗しました:", error);
      });
  },
};
