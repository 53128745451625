<template>
  <v-table>
    <thead>
      <tr>
        <th
          v-for="(header, index) in headers"
          :key="index"
          class="text-left"
          :width="header.width"
        >
          {{ header.text }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in data" :key="item.name">
        <th>{{ item.name }}</th>
        <td
          v-for="(header, index) in headers.slice(1)"
          :key="index"
          class="table-cell"
        >
        <span v-html="item[header.value]"></span>
        </td>
      </tr>
    </tbody>
  </v-table>
</template>

<script src="./script.js"></script>
<style src="./style.css" lang="css" scoped></style>
