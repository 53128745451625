import { fetchInsurerList,fetchCompanyCountByIndustry } from "@/services/api/companyInfoService";

export default {
  data() {
    return {
      nav_lists: [
        {
          name: "ACTION!健康経営",
          href: "https://kenko-keiei.jp/",
          prependAvatarUrl:
            "https://th.bing.com/th?id=ODLS.79b77d17-8ed2-4031-aa08-ef6822828d4d&w=32&h=32&qlt=90&pcl=fffffa&o=6&pid=1.2",
        },
        {
          name: "伸びしろ！フォーム",
          href: "https://forms.office.com/r/hgR3AAstNN",
          prependAvatarUrl:
            "https://menter.jp/blog/wp-content/uploads/2023/02/forms1.webp",
        },
      ],
      reloadflag: true,
      insurer_list: [],
      companyCountList: [],
      selectedInsurer: null,
      filteredInsurerList: [],
    };
  },
  watch: {
    $route(routeInstance) {
      this.createTitleDesc(routeInstance);
    },
  },
  methods: {
    handleClick() {
      this.$router.push({
        name: "homepage",
        params: { industryName: "全業種" },
      });
    },
    handleListItemClick(industry, industryCode) {
      // パラメータとして業種名を渡してHomePage.vueに遷移
      this.$router.push({
        name: "homepage",
        params: { industryName: industry, industryCode: industryCode },
      });
    },
    createTitleDesc: function (routeInstance) {
      //titleを設定する処理
      if (routeInstance.meta.title) {
        var setTitle = routeInstance.meta.title + " | WellCom";
        document.title = setTitle;
      } else {
        document.title = "WellCom -健康経営フィードバックシートポータルサイト-";
      }
    },
    getInsurerList() {
      // 保険組合リストを取得
      fetchInsurerList()
        .then((response) => {
          this.insurer_list = response.data.insurer_list;
          this.filteredInsurerList = this.insurer_list;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filterInsurerList(query) {
      if (typeof query === "string" && query) {
        this.filteredInsurerList = this.insurer_list.filter((insurer) =>
          insurer.toLowerCase().includes(query.toLowerCase())
        );
      } else {
        this.filteredInsurerList = this.insurer_list;
      }
    },
    handleInsurerSelect() {
      const selected = this.filteredInsurerList.find(
        (item) => item === this.selectedInsurer
      );
      if (this.selectedInsurer && selected) {
        this.$router.push({
          name: "homepage",
          params: { insurerName: this.selectedInsurer },
        });
        this.selectedInsurer = null;
      }
    },
    getCompanyCountByInstustry() {
      // 業種ごとの企業数を取得
      fetchCompanyCountByIndustry()
      .then((response) => {
        const data = response.data.industry_counts;
        this.companyCountList = Object.keys(data).map((key) => ({
          name: key,
          count: data[key],
        }));
      })
      .catch((error) => {
        console.log(error);
      });
    },
  },
  mounted: function () {
    var routeInstance = this.$route;
    this.createTitleDesc(routeInstance);
    this.getInsurerList();
    this.getCompanyCountByInstustry();
  },
};
