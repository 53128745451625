export default {
  props: {
    mainCard: {
      type: Object,
      required: true,
    },
    referenceTitle: {
      type: String,
      required: true,
    },
    referenceCards: {
      type: Array,
      required: true,
    },
  },
};
